import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import keyword from 'src/module/keyword';
import Auth from '../../module/Auth';

const KeywordLimit = ({ AddKeyword, deleted, keywordData }) => {
  const [keywordCount, setKeywordCount] = useState(0);
  const [error, setError] = useState(false);
  const [userId, setUserId] = useState(Auth.getUserLoginId());

  useEffect(() => {
    keyword.keywordsLimit(userId, (response) => {
      if (response.status === 'success') {
        setKeywordCount(response.data);

        localStorage.setItem('r_k', JSON.stringify(response.data));
      } else if (response.data.response.status === 509) {
        const count = 10;
        const sub = count - keywordData.length;

        localStorage.setItem('r_k', JSON.stringify(sub));

        setKeywordCount(sub);
      } else {
        setError(true);
      }
    });
  }, [AddKeyword, deleted, keywordData]);

  const isCd = Auth.getIsCd();

  return (
    <Box>
      {error ? (
        <Typography variant="subtitle2" color="error">
          Error fetching keyword limit. Please try again later.
        </Typography>
      ) : (
        <>
          {!isCd && (
            <div style={{ flex: '1 0 auto', margin: 'auto', padding: '10px', fontSize: '15px', fontWeight: 'bold' }}>
              Keywords Left : {keywordCount}
            </div>
          )}
        </>
      )}
    </Box>
  );
};
export default KeywordLimit;
