import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import { Pagination } from "@mui/lab";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  ButtonBase,
  ButtonGroup,
  CircularProgress,
  Grid,
  LinearProgress,
  Link,
  Stack,
  TablePagination,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactCountryFlag from "react-country-flag";
import KeywordLimit from "src/components/keywordsLimit/limitKeywords";
import Iconify from "../iconify/Iconify";
import KeywordHistory from "../keyword-history/keywordHistory";
import { MyContext } from "../../pages/DashboardAppPage";
import domain from "../../module/domain";
import keyword from "../../module/keyword";
import position from "../../module/position";
import DownloadButton from "../csv-download";
import AddKeyword from "../keyword/addKeyword";
import Auth from "../../module/Auth";
import "./collapse.css";

function createData(keyword, position, id, favourite, code) {
  return {
    keyword,
    position,
    id,
    favourite,
    code,
  };
}

function Row(props) {
  const {
    row,
    selectedRows,
    handleCheckboxChange,
    setDeleted,
    deleted,
    setKeywordData,
    keywordData,
    domainName,
    setCount,
    setRefresh,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(props?.row);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [loading, setLoading] = React.useState(false);
  const [sortDirection, setSortDirection] = React.useState("asc");

  useEffect(() => {}, [rowData, keywordData]);
  const handleReload = (id) => {
    if (!isCd) {
      setLoading(true);
      position.refreshPosition(id, (response) => {
        if (response.status === "success") {
          const data = {
            code: response.data?.code,
            favourite: response.data?.favourite,
            id: response.data?.id,
            keyword: response.data?.keyword,
            position: response.data?.position,
          };
          setRowData(data);
          setKeywordData((prev) => [...prev]);
          setRefresh((prev) => !prev);
          // if (open) {
          //   handleOtherLinksData(response.data?.id);
          // }

          setLoading(false);
        }
        setLoading(false);
      });
    }
  };

  const handleDelete = (rowId) => {
    if (!isCd) {
      keyword.deleteKeyword(rowId, (response) => {
        if (response.status === "success") {
          setDeleted(!deleted);
          setKeywordData((prevKeywordData) =>
            prevKeywordData.filter((row) => row.id !== rowId)
          );
        }
      });
    }
  };

  const handleAlert = (id) => {
    if (Auth?.isSubscribed()) {
      window.alert("Delete option is not available for free account");
    } else {
      const res = window.confirm(
        "Are you sure you want to delete this keyword?"
      );

      handleDelete(id);
    }
  };
  const handleFavorite = (id, param) => {
    if (!isCd) {
      let fav;
      if (param === "yes") {
        fav = true;
      } else {
        fav = false;
      }
      const data = {
        favourite: fav,
      };
      keyword.updateKeyword(id, data, (response) => {
        setLoading(true);
        if (response.status === "success") {
          setRowData({ ...rowData, favourite: response.data.favourite });
          setLoading(false);
        }
        setLoading(false);
      });
    }
  };
  const handleOpen = () => {
    setOpen(!open);
  };

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativeTime);

  // Get the user's local time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Use the `utc` method to parse the ISO timestamp string and convert it to UTC time
  const utcTime = dayjs.utc(rowData?.position?.creationDate);

  // Use the `tz` method to convert the UTC time to the user's local time zone
  const localTime = utcTime.tz(userTimeZone);

  const handleEye = () => {
    if (rowData?.position?.url === null) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const isCd = Auth.getIsCd(); //

  return (
  
      <>
        {/* <Item> */}
        <TableRow className="table-row" key={rowData?.id}>
          <TableCell className="t1" key={rowData?.id}>
            <Box display={"flex"} flexDirection={"row"} m={0} p={0}>
              {isCd || Auth?.isSubscribed() ? null : (
                <Checkbox
                  checked={selectedRows && selectedRows.includes(row.id)}
                  onChange={(event) => handleCheckboxChange(event, row.id)}
                />
              )}
              <IconButton
                key={rowData?.id}
                aria-label="expand row"
                size="small"
              >
                <Iconify
                  onClick={handleEye}
                  icon={"mdi:eye"}
                  width={16}
                  height={16}
                />
              </IconButton>
            </Box>

            {open ? (
              <KeywordHistory
                rowData={rowData}
                position={rowData?.position?.position}
                // chartData={chartData}
                // otherLinks={otherLinks}
                open={open}
                handlePropClose={handleOpen}
              />
            ) : null}
          </TableCell>
          <TableCell
            scope="row"
            key={rowData?.id}
            sx={{ maxWidth: "500px", overflow: "clip" }}
          >
            <Link
              target={"_blank"}
              rel="noopener"
              href={rowData?.position?.url}
            >
              {rowData?.position?.url}
            </Link>
          </TableCell>
          <TableCell table-row align="right">
            {
              <>
                {rowData?.keyword} &nbsp;
                <ReactCountryFlag
                  countryCode={rowData.code}
                  svg
                  cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                  cdnSuffix="svg"
                  title="flag"
                />
              </>
            }
          </TableCell>
          <TableCell
            className="table-row"
            align="right"
            style={{
              color:
                rowData?.position == null || rowData?.position?.position == null
                  ? "gray"
                  : "black",
            }}
          >
            {!loading ? (
              <>
                {rowData?.position !== null &&
                rowData?.position?.position !== null
                  ? rowData?.position?.position
                  : ">100"}
              </>
            ) : (
              <LinearProgress />
            )}
          </TableCell>
          <TableCell align="right" key={rowData?.id}>
            {!loading ? (
              <>
                {rowData.position?.dayStats != null &&
                rowData.position?.dayStats !== 0 ? (
                  <>
                    {rowData?.position?.dayStats.toString().replace(/^-/, "")}{" "}
                    {Math.sign(rowData?.position?.dayStats) === 1 ? (
                      <Tooltip title="Going Good">
                        <Iconify
                          icon={"ant-design:caret-up-filled"}
                          color={"green"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Down">
                        <Iconify
                          icon={"ant-design:caret-down-filled"}
                          color={"red"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </>
            ) : (
              <LinearProgress />
            )}
          </TableCell>
          <TableCell align="right" key={rowData?.id}>
            {!loading ? (
              <>
                {rowData.position?.weekStats != null &&
                rowData.position?.weekStats !== 0 ? (
                  <>
                    {rowData?.position?.weekStats.toString().replace(/^-/, "")}{" "}
                    {Math.sign(rowData?.position?.weekStats) === 1 ? (
                      <Tooltip title="Going Good">
                        <Iconify
                          icon={"ant-design:caret-up-filled"}
                          color={"green"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Down">
                        <Iconify
                          icon={"ant-design:caret-down-filled"}
                          color={"red"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </>
            ) : (
              <LinearProgress />
            )}
          </TableCell>
          <TableCell align="right" key={rowData?.id}>
            {!loading ? (
              <>
                {rowData.position?.monthStats != null &&
                rowData.position?.monthStats !== 0 ? (
                  <>
                    {rowData?.position?.monthStats.toString().replace(/^-/, "")}{" "}
                    {Math.sign(rowData?.position?.monthStats) === 1 ? (
                      <Tooltip title="Going Good">
                        <Iconify
                          icon={"ant-design:caret-up-filled"}
                          color={"green"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Down">
                        <Iconify
                          icon={"ant-design:caret-down-filled"}
                          color={"red"}
                          width={14}
                          height={10}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </>
            ) : (
              <LinearProgress />
            )}
          </TableCell>
          <TableCell className="action" align="right" key={rowData?.id}>
            <Box display={"flex"} flexDirection={"row"} marginLeft={3}>
              {rowData?.favourite ? (
                <>
                  <Tooltip title="Favorite">
                    <Iconify
                      icon={"uim:favorite"}
                      width={20}
                      height={18}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleFavorite(rowData?.id, "no")}
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Add Favorite">
                    <Iconify
                      icon={"mi:favorite"}
                      width={20}
                      height={18}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleFavorite(rowData?.id, "yes")}
                    />
                  </Tooltip>
                </>
              )}

              <Tooltip title={"Refresh"}>
                <Iconify
                  icon={"ion:reload-circle"}
                  width={20}
                  height={18}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleReload(rowData?.id)}
                />
              </Tooltip>
              {!isCd && (
                // <Tooltip title={"Delete"} placement="top">
                <Tooltip
                  title={
                    Auth?.isSubscribed()
                      ? "Delete option is disabled for free account"
                      : "Delete"
                  }
                  placement="top"
                >
                  <Iconify
                    icon={"ic:baseline-delete"}
                    width={20}
                    height={18}
                    sx={{
                      cursor: "pointer",
                      color: Auth?.isSubscribed() ? "gray" : "#ff0000bf",
                    }}
                    onClick={() => handleAlert(rowData?.id)}
                  />
                </Tooltip>
              )}
            </Box>
          </TableCell>

          <TableCell
            className="table-row"
            align="right"
            key={rowData?.position?.id}
          >
            <Typography
              align="center"
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {rowData?.position !== null &&
              rowData?.position?.position !== null
                ? localTime.fromNow({ includeSeconds: true })
                : "-"}
            </Typography>
          </TableCell>
        </TableRow>
        {/* </Item> */}
      </>
    
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    position: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    favourite: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
};

export default function CollapsibleTable() {
  const {
    domainName,
    tableLoading,
    keywordData,
    setKeywordData,
    refresh,
    setRefresh,
  } = React.useContext(MyContext);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [page, setPage] = React.useState(0);
  const [deleted, setDeleted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [deviceName, setDeviceName] = React.useState("desktop");
  const [selected, setSelected] = React.useState(true);
  const [mobileSelected, setMobileSelected] = React.useState(false);
  const [desktopSelected, setDesktopSelected] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [sortAscending, setSortAscending] = React.useState(true);

  React.useEffect(() => {
    if (domainName?.id) getDomainById(page, deviceName);
  }, [
    domainName,
    tableLoading,
    rowsPerPage,
    page,
    deviceName,
    deleted,
    refresh,
  ]);

  const getDomainById = (pagenum, deviceName) => {
    setLoading(true);

    domain.getByDomainId(
      domainName?.id,
      page,
      rowsPerPage,
      deviceName,
      (response) => {
        if (response.status === "success") {
          const totalRows = response.data.totalRows;
          const rows = response.data.keywordDetails?.map((item) => {
            return createData(
              item?.keyword,
              item?.position,
              item?.id,
              item?.favourite,
              item?.code
            );
          });

          setCount(totalRows);
          setKeywordData(rows);

          setTimeout(() => {
            setLoading(false);
          }, 500);
        } else {
          setLoading(false);

          setKeywordData([]);
        }
      }
    );
  };

  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;

  const handleSortClick = () => {
    setSortAscending(!sortAscending);
    sortData();
  };

  const sortData = () => {
    const sortedData = [...keywordData].sort((a, b) => {
      const positionA = a.position ? a.position.position : null;
      const positionB = b.position ? b.position.position : null;

      if (positionA === null && positionB === null) {
        return 0;
      }
      if (positionA === null) {
        return 1;
      }
      if (positionB === null) {
        return -1;
      }
      if (sortAscending) {
        return positionA - positionB;
      } else {
        return positionB - positionA;
      }
    });
    setKeywordData(sortedData);

    const filteredData = sortedData?.slice(startIndex, endIndex);
  };

  // const totalRows = React.useMemo(() => getDomainById(page, deviceName), [page, deviceName]);
  // console.log(totalRows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage); // Calculate the new page based on the updated rowsPerPage.

    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const handleRemove = () => {
    setDeleted(!deleted);
  };

  const handleDeleteAllAlert = () => {
    if (Auth?.isSubscribed()) {
      window.alert("Delete option is not available for free account");
    } else {
      const res = confirm("Are you sure, you want to delete all keywords");
      if (res) {
        handleDeleteAll();
      }
    }
  };

  const handleDeleteAll = () => {
    setLoading(true);
    keyword.deleteAllKeywords(domainName?.id, (response) => {
      if (response.status === "success") {
        setKeywordData([]);
        getDomainById(0, "desktop");
      }
    });
    setLoading(false);
  };

  const handleRefreshAll = () => {
    if (!isCd) {
      setLoading(true);
      const ids = keywordData.map((data) => data.id);
      const chunkSize = 20; // Number of IDs to send per API request
      const totalChunks = Math.ceil(ids.length / chunkSize); // Total number of chunks

      const sendChunk = (chunkIndex) => {
        const start = chunkIndex * chunkSize;
        const end = start + chunkSize;
        const chunkIds = ids.slice(start, end);

        try {
          keyword.reloadAllKeywords(chunkIds, (response) => {
            const data = response.data;
            if (response.status === "success") {
              setLoading(false);
              // const totalRows = response.data.totalRows;
              setRefresh(!refresh);
              const updatedRows = response.data;

              setKeywordData((prevData) => {
                // Update the keywordData by replacing the relevant chunks
                return prevData.map((item) => {
                  if (updatedRows.find((row) => row.id === item.id)) {
                    return updatedRows.find((row) => row.id === item.id);
                  } else {
                    return item;
                  }
                });
              });
            }
          });

          //   const ro=   data?.map((item) => {
          //        return createData(item?.keyword, item?.position, item?.id, item?.favourite, item?.code);
          //      });
          //      setKeywordData(ro);
          //    }

          //  });

          // setKeywordData((prevData) => {
          //   // Update the keywordData by replacing the relevant chunks
          //   const newData = [...prevData];
          //   chunkIds.forEach((id, index) => {
          //     const dataIndex = start + index;
          //     newData[dataIndex] = response.data.find((item) => item.id === id);
          //   });

          //   return newData;

          // });

          if (chunkIndex < totalChunks - 1) {
            setTimeout(() => sendChunk(chunkIndex + 1), 1000); // Send the next chunk after a short delay
          }
        } catch (error) {
          setLoading(false);
        }
      };

      sendChunk(0);
    }
  };

  const handleDeviceChange = (device) => {
    setDeviceName(device);
    if (device === "desktop") {
      setSelected(true);
      setMobileSelected(false);
    } else {
      setSelected(false);
      setMobileSelected(true);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = keywordData.map((row) => row.id);
      setSelectedRows(newSelected);
    } else {
      selectedRows([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    }
  };

  const handleDeleteSelectedAlert = () => {
    const res = confirm("Are you sure, you want to delete selected keywords");
    if (res) {
      handleDeleteSelected(selectedRows);
    }
  };

  const handleDeleteSelected = (selectedRows) => {
    setLoading(true);

    selectedRows.forEach((rowId) => {
      keyword.deleteKeyword(rowId, (response) => {
        if (response.status === "success") {
          setDeleted(!deleted);
          setKeywordData((prevKeywordData) =>
            prevKeywordData.filter((row) => row.id !== rowId)
          );
        }
      });
    });

    setSelectedRows([]);
    setLoading(false);
  };

  const onSelect = (keywordId) => {
    const isSelected = selectedRows.some((row) => row.id === keywordId);
    let newSelected = [];

    if (!isSelected) {
      const selectedKeyword = keywordData.find((row) => row.id === keywordId);
      newSelected = [...selectedRows, selectedKeyword];
    } else {
      newSelected = selectedRows.filter((row) => row.id !== keywordId);
    }

    setSelectedRows(newSelected);
  };

  const isCd = Auth.getIsCd(); //

  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <ButtonGroup variant="outlined" aria-label="split button">
            <Tooltip title={"Device Desktop"}>
              <Button
                onClick={() => handleDeviceChange("desktop")}
                variant={selected ? "contained" : "outlined"}
                color={selected ? "success" : "primary"}
              >
                <Iconify icon={"material-symbols:desktop-mac-outline"} />
              </Button>
            </Tooltip>
            <Tooltip title={"Device Mobile"}>
              <Button
                variant={mobileSelected ? "contained" : "outlined"}
                onClick={() => handleDeviceChange("mobile")}
                color={mobileSelected ? "success" : "primary"}
              >
                <Iconify icon={"mdi:mobile-phone"} />
              </Button>
            </Tooltip>
          </ButtonGroup>
          <Grid item display={"flex-start"}>
            <KeywordLimit
              AddKeyword={AddKeyword}
              deleted={deleted}
              loading={loading}
              keywordData={keywordData}
            />
          </Grid>
        </Grid>
        {keywordData?.length > 0 && (
          <Grid item xs>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              {!isCd && (
                <Grid item>
                  <Tooltip title={"Delete All"} placement="top">
                    <Iconify
                      icon={"ic:baseline-folder-delete"}
                      width={25}
                      height={25}
                      sx={{
                        cursor: "pointer",
                        color: "#ff0000bf",
                      }}
                      onClick={() => handleDeleteAllAlert()}
                    />
                  </Tooltip>
                </Grid>
              )}

              <Grid item>
                <Tooltip title={"Refresh All"} placement="top">
                  {!loading ? (
                    <Iconify
                      icon={"mdi:camera-refresh"}
                      width={25}
                      height={25}
                      sx={{
                        cursor: "pointer",
                        color: "green",
                      }}
                      onClick={() => handleRefreshAll()}
                    />
                  ) : (
                    <>
                      <CircularProgress size={18} />
                    </>
                  )}
                </Tooltip>
              </Grid>
              {!isCd && !Auth?.isSubscribed() ? (
                <>
                  <Grid item>
                    <DownloadButton countSize={count} />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* {!loading ? (
    <> */}

      <TableContainer
        sx={{
          height: "500px !important",
          scrollBehavior: "smooth",
          zIndex: 999,
          overflowY: "scroll", // Use 'scroll' to enable scrolling
          "&::-webkit-scrollbar": {
            width: "0.2em", // Set the width of the scrollbar
            backgroundColor: "white", // Hide the track of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#0db6f6",
            borderRadius: "10px",
          },
        }}
        component={Paper}
      >
        <Table key={refresh} stickyHeader={true} aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {selectedRows.length === 0 ? (
                <TableCell className="p1">{isCd ? null : "Select"}</TableCell>
              ) : (
                <TableCell>
                  <Iconify
                    icon={"mdi:delete"}
                    width={25}
                    height={25}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDeleteSelectedAlert()}
                  />
                </TableCell>
              )}
              <TableCell className="p2">Keyword Url</TableCell>
              <TableCell className="p3" align="right">
                Keyword
              </TableCell>
              <TableCell className="position" align="right" width={"150px"}>
                {" "}
                Position &nbsp;
                <Tooltip title="Sort" placement="top">
                  <Iconify
                    icon="solar:round-sort-vertical-bold"
                    color="#00A3D8"
                    width={18}
                    height={15}
                    onClick={handleSortClick}
                  />
                </Tooltip>
              </TableCell>

              <TableCell align="right">1d</TableCell>
              <TableCell align="right">7d</TableCell>
              <TableCell align="right">1m</TableCell>
              <TableCell align="center">Action</TableCell>
              <TableCell align="center">Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keywordData?.map((row) => (
              <Row
                key={row?.id}
                row={row}
                handleCheckboxChange={handleCheckboxChange}
                setDeleted={setDeleted}
                deleted={deleted}
                refresh={refresh}
                setKeywordData={setKeywordData}
                keywordData={keywordData}
                // Pass the handleDelete function as a prop
              />
            ))}
          </TableBody>
        </Table>
        {keywordData?.length > 0 ? (
          <>
            <TablePagination
              rowsPerPageOptions={[30, 100, 500, 1000]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <strong>No Keyword found for this domain! </strong>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="nothing found"
                src="/assets/nothing.jpg"
                width={"500px"}
                height={"380px"}
              />
            </div>
          </>
        )}
      </TableContainer>
      {/* </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )} */}
    </Box>
  );
}
