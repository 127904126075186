import { Helmet } from 'react-helmet-async';
import React, { useState, createContext } from 'react';

import { useNavigate } from 'react-router-dom';

// @mui
import { Grid, Container, Typography, Button, Tooltip } from '@mui/material';

import { Support } from 'src/components/support/support';

import Iconify from 'src/components/iconify/Iconify';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Auth from '../module/Auth';
import DomainDropDown from '../components/domain-drop-down';
import useResponsive from '../hooks/useResponsive';
import AddKeyword from '../components/keyword/addKeyword';
import SearchComponent from '../components/tag/addTag';
import CollapsibleTable from '../components/collapse-table/collapse';

// import { ManageSubscription } from './ManageSubscriptions';

export const MyContext = createContext();

// ----------------------------------------------------------------------

export default function DashboardAppPage({ isCd, domain }) {
  const [domainName, setDomainName] = useState(null);
  const [keywordData, setKeywordData] = useState([]);
  const [open, setOpenContext] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [ups, setUps] = useState('');
  const [downs, setDowns] = useState('');
  const [avg, setAvg] = useState('');
  const [buttonText, setButtonText] = useState('Share Report');
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // media query will show up to small screen only //
  const mdUp = useResponsive('up', 'sm');
  const navigate = useNavigate();
  const handleOpen = () => {
    console.log('e');
    setModalOpen(true);
  };

  const handleClose = () => setModalOpen(false);

  const searchDomain = domainName?.id;

  const handleShare = () => {
    //  const baseUrl = 'https://devtrackerfe-dfy6gslhza-uc.a.run.app/client-dashboard';
    const baseUrl = 'https://app.marketinglad.io/client-dashboard';

    const token = Auth.getToken();

    // Retrieve domain name and ID from local storage
    const domainDataString = localStorage.getItem('domainName');
    const domainData = JSON.parse(domainDataString);

    const domainName = domainData.domain;
    const domainId = domainData.id;

    const url = `${baseUrl}?state=${true}&token=${token}&domainName=${domainName}&domainId=${domainId}`;

    navigator.clipboard
      .writeText(url)
      .then(() => {
        setButtonText('Copied');
        setTimeout(() => {
          setButtonText('Share Report');
        }, 2000); // 2 seconds delay
      })
      .catch((error) => {
        console.error('Error copying URL to clipboard:', error);
        alert('Failed to copy URL');
      });
  };

  // const searchDomain = domainName?.id;

  // const handleShare = () => {
  //   const baseUrl = 'https://devtrackerfe-dfy6gslhza-uc.a.run.app/client-dashboard';
  //   const token = Auth.getToken();

  //   // Retrieve domain name and ID from local storage
  //   const domainDataString = localStorage.getItem('domainName');
  //   const domainData = JSON.parse(domainDataString);

  //   const domainName = domainData.domain;
  //   const domainId = domainData.id;

  //   const url = `${baseUrl}?state=${true}&token=${token}&domainName=${domainName}&domainId=${domainId}`;

  //   navigator.clipboard
  //     .writeText(url)
  //     .then(() => {
  //       setButtonText('Copied');
  //       setTimeout(() => {
  //         setButtonText('Share Report');
  //       }, 2000); // 2 seconds delay
  //     })
  //     .catch((error) => {
  //       console.error('Error copying URL to clipboard:', error);
  //       alert('Failed to copy URL');
  //     });
  // };

  const hanldeUpgrade = () => {
    navigate('/subscription');
  };
  return (
    <>
      <Helmet>
        <title> Marketing Lad | Rank Tracker </title>
      </Helmet>

      <Container maxWidth="xl">
        <MyContext.Provider
          value={{
            domainName,
            setDomainName,
            open,
            setOpenContext,
            tableLoading,
            setTableLoading,
            category,
            setCategory,
            keywordData,
            setKeywordData,
            ups,
            downs,
            avg,
            setUps,
            setDowns,
            setAvg,
            refresh,
            setRefresh,
          }}
        >
          <a
            style={{ color: '#000', fontWeight: 700, fontSize: '11px' }}
            href="https://marketinglad.io/b2b-seo-agency"
            target="_blank"
            rel="noreferrer"
          >
            Contact Sales
          </a>
          <Grid item xs={12} sm={4} md={6} display="flex" justifyContent={'space-between'} flexWrap="wrap-reverse">
            {!isCd && (
              <Grid display="flex">
                {mdUp && (
                  <Typography variant="h5" sx={{ mb: 5, mr: 3 }}>
                    Hi, {`${Auth.getName()}`}
                  </Typography>
                )}

                {!isCd && (
                  <Grid item sm={12} mb={1} mr={2}>
                    <AddKeyword />
                  </Grid>
                )}

                {!isCd && (
                  <Grid item sm={12} mb={1} mr={2} ml={2}>
                    <Button onClick={handleShare} variant="outlined" color="primary">
                      {buttonText}
                    </Button>
                  </Grid>
                )}

                {!isCd && (
                  <Grid item sm={12}>
                    <SearchComponent searchDomain={searchDomain} />
                  </Grid>
                )}
              </Grid>
            )}
            {Auth?.isSubscribed() ? (
              <>
                <Grid item sm={12} mb={1} ml={9}>
                  <Button onClick={hanldeUpgrade} variant="outlined" color="primary">
                    Upgrade
                  </Button>
                </Grid>
              </>
            ) : null}

            <Grid display={'flex'} justifyContent={'space-around'}>
              <Grid item sm={12}>
                <Tooltip title="Support">
                  <Iconify
                    color={'#19a1fc'}
                    onClick={handleOpen}
                    icon={'mdi:support'}
                    width={32}
                    height={32}
                    cursor={'pointer'}
                  />
                </Tooltip>
              </Grid>
              &nbsp;
              <Grid>
                <DomainDropDown domain={domain} />
              </Grid>
            </Grid>
          </Grid>
          <>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4} md={4}>
                <AppWidgetSummary title="UP'S" total={714000} icon={'ant-design:caret-up-filled'} />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <AppWidgetSummary title="DOWN'S" total={234} color="error" icon={'ant-design:caret-down-filled'} />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <AppWidgetSummary
                  title="AVERAGE"
                  total={1723315}
                  color="warning"
                  icon={'ant-design:vertical-align-middle-outlined'}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CollapsibleTable />
              </Grid>
            </Grid>
          </>
        </MyContext.Provider>
      </Container>
      <Support openModal={modalOpen} handleClose={handleClose} />
    </>
  );
}
