import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CircularProgress, Box, Grid, Typography, Button, Container } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Header from 'src/layouts/dashboard/header';
import Auth from 'src/module/Auth';
import Iconify from 'src/components/iconify/Iconify';
import user from 'src/module/user';
import { replace } from 'lodash';

// const stripePromise = loadStripe(
//   'pk_live_51MEUFfSHlBafozrZxctS7UzxWHqaBmHZxXHlnhsfntxjpqkl5Hk2256Smk6Ck2hssTJM5PXYVongmhIuKmzxIGgB00Z6U6jNxz'
// );

function PricingPage() {
  const stripePromise = loadStripe(
    'pk_live_51MEUFfSHlBafozrZxctS7UzxWHqaBmHZxXHlnhsfntxjpqkl5Hk2256Smk6Ck2hssTJM5PXYVongmhIuKmzxIGgB00Z6U6jNxz'
  );

  // ...

  const [isFree, setIsFree] = React.useState(Auth.getIsFree());
  const [loading, setLoading] = React.useState(false);
  // const [isYearly, setIsYearly] = React.useState(false);
  // const [price, setPrice] = React.useState(null);

  // // Replace these with the actual price IDs from your Stripe Dashboard
  // const monthlyPriceId = 'price_1O4ekOSHlBafozrZeSU4JZ9c';
  // const yearlyPriceId = 'price_1O4gk6SHlBafozrZc5j2LPqo';
  // const selectedPriceId = isYearly ? yearlyPriceId : monthlyPriceId;

  // React.useEffect(() => {
  //   const fetchPrice = async () => {
  //     const priceId = isYearly ? 'price_1O4gk6SHlBafozrZc5j2LPqo' : 'price_1O4ekOSHlBafozrZeSU4JZ9c';
  //     try {
  //       const response = await axios.get(`https://api.stripe.com/v1/prices/${priceId}`, {
  //         headers: {
  //           Authorization: `Bearer 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`,
  //         },
  //       });
  //       setPrice(response.data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Error retrieving price:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchPrice();
  // }, [isYearly]);

  const Accordion = styled((props) => <MuiAccordion disableGutters elevation={2} square {...props} />)(
    ({ theme, isOpen }) => ({
      border: `1px solid ${theme.palette.divider}`,
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      backgroundColor: isOpen ? '#F0F0F0' : 'white', // Toggle background color
      color: isOpen ? 'blue' : 'black', // Toggle text color
      transition: 'background-color 0.3s, color 0.3s', // Add transition effect
    })
  );

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<Iconify icon="material-symbols:expand-more" sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  // const fetchCustomer = async () => {
  //   setLoading(true);
  //   try {
  //     const secretKey =
  //       'sk_test_51M2TNuSJzTmU4b1VQtahgXp2hnpztc7iOG7LWH1GFQZEvzqdvcJEd5RFwKExOCBvSVWxJczFT9Fg8NaeGDTjDhSx00JRNIVWwP'; // Replace with your Stripe secret key

  //     const customerId = Auth.getCustomerId(); // Replace with the actual customer ID

  //     const response = await axios.get(`https://api.stripe.com/v1/customers/${customerId}`, {
  //       headers: {
  //         Authorization: `Bearer ${secretKey}`,
  //       },
  //     });

  //     if (response.data.email != undefined) {
  //       const response = await axios.post(
  //         'https://api.stripe.com/v1/billing_portal/sessions',
  //         `customer=${customerId}`,
  //         {
  //           headers: {
  //             Authorization: ` Bearer sk_test_51M2TNuSJzTmU4b1VQtahgXp2hnpztc7iOG7LWH1GFQZEvzqdvcJEd5RFwKExOCBvSVWxJczFT9Fg8NaeGDTjDhSx00JRNIVWwP`,

  //             'Content-Type': 'application/x-www-form-urlencoded',
  //           },
  //         }
  //       );

  //       const portalUrl = response.data.url;
  //       if (portalUrl) {
  //         window.location.href = portalUrl;
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error fetching customer data:', error);
  //     setLoading(false);
  //   }
  // };

  const handleFreeSubscription = () => {
    const data = {
      subscribed: true,
    };
    user.updateUser(Auth.getUserLoginId(), data, (response) => {
      if (response.status === 'success') {
        const data = JSON.parse(localStorage.getItem('Auth'));
        data.userDetails = response.data;
        localStorage.setItem('Auth', JSON.stringify(data));
        location.replace('/dashboard');
      }
    });
  };

  // const toggleBillingPeriod = () => {
  //   setIsYearly(!isYearly);
  // };

  return (
    <>
      <Header />

      {loading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <CircularProgress size={28} />
        </div>
      ) : (
        <>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            flexDirection={'column'}
            sx={{
              // backgroundColor: '#00A3D8',
              backgroundImage: 'linear-gradient( rgba(0, 161, 214, 0.54),#fff)',
              height: '700px',
              alignItems: 'center',
              // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <Grid
              sx={{
                marginTop: '-200px',
              }}
            >
              <Typography display={'flex'} justifyContent={'center'} variant="h2" align="center">
                Unlock the Power of ML Rank Tracker
              </Typography>
              {isFree && !Auth?.isSubscribed() ? (
                <Typography display={'flex'} justifyContent={'center'} variant="p" align="center">
                  Free Access + Premium Upgrades for Unrivaled SERP Insights!
                </Typography>
              ) : null}
            </Grid>
            <br />
            {isFree && !Auth?.isSubscribed() ? (
              <Typography
                sx={{
                  position: 'absolute',
                  top: '380px',
                  left: '200px',
                  '@media screen and (min-width: 200px) and (max-width: 644px)': {
                    left: '100px',
                  },
                  '@media screen and (min-width: 791px) and (max-width: 1087px)': {
                    left: '400px',
                  },
                  '@media screen and (min-width: 1088px)': {
                    left: '200px',
                  },
                }}
                variant="h3"
              >
                Free Plan
              </Typography>
            ) : null}
          </Box>

          {isFree && !Auth?.isSubscribed() ? (
            <section id="contact">
              <Container
                zIndex="9"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingY: '20px',
                  flexWrap: 'wrap',
                  borderRadius: '30px 100px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  marginTop: '-330px',
                  // opacity: '0.7',
                  backgroundImage: 'linear-gradient(#fff, rgba(0, 161, 214, 0.54))',
                }}
                width={'600px'}
              >
                <Grid
                  margin={'10px'}
                  width={'500px'}
                  height={'300px'}
                  padding={'30px'}
                  flexWrap={'wrap'}
                  sx={{
                    borderImage: 'linear-gradient(to bottom, rgba(0, 161, 214, 0.54), white) 1',
                    borderImageSlice: 1,
                    borderRight: '1px solid white',
                  }}
                >
                  <h2>Unlock Limitless Possibilities, Embrace Our Free Plan Today!</h2>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ width: '345px' }}
                    onClick={handleFreeSubscription}
                  >
                    {' '}
                    Subscribe for Free{' '}
                  </Button>
                </Grid>

                <Grid
                  width={'500px'}
                  height={'300px'}
                  margin={'10px'}
                  padding={'30px'}
                  sx={{
                    '@media screen and (max-width: 1087px)': {
                      marginTop: '-150px',
                    },
                  }}
                >
                  <h3>
                    <strong
                      style={{
                        marginLeft: '25px',
                      }}
                    >
                      {' '}
                      Features
                    </strong>
                  </h3>
                  <Grid display={'flex'} flexDirection={'row'} mb={0.5}>
                    <Iconify icon="mdi:tick-circle" color="green" marginRight="5px" />
                    <Typography mt={-0.3}>100 Keywords</Typography>
                  </Grid>
                  <Grid display={'flex'} flexDirection={'row'} mb={0.5}>
                    <Iconify icon="mdi:tick-circle" color="green" marginRight="5px" />
                    <Typography mt={-0.3}>2 Domains</Typography>
                  </Grid>
                  <Grid display={'flex'} flexDirection={'row'} mb={0.5}>
                    <Iconify icon="mdi:tick-circle" color="green" marginRight="5px" />
                    <Typography mt={-0.3}>Automatic Daily Refresh</Typography>
                  </Grid>
                  <Grid display={'flex'} flexDirection={'row'} mb={0.5}>
                    <Iconify icon="mdi:tick-circle" color="green" marginRight="5px" />
                    <Typography mt={-0.3}>Desktop Tracking</Typography>
                  </Grid>
                  <Grid display={'flex'} flexDirection={'row'} mb={0.5}>
                    {' '}
                    <Iconify icon="mdi:tick-circle" color="green" marginRight="5px" />
                    <Typography mt={-0.3}> Mobile Tracking</Typography>
                  </Grid>

                  <Grid display={'flex'} flexDirection={'row'}>
                    <Iconify icon="mdi:tick-circle" color="green" marginRight="5px" />

                    <Typography mt={-0.3}>Refresh Keywords </Typography>
                  </Grid>
                  <Grid display={'flex'} flexDirection={'row'}>
                    <Iconify icon="gridicons:cross-circle" color="red" marginRight="5px" />

                    <Typography mt={-0.3}>Delete Keywords </Typography>
                  </Grid>
                  <Grid display={'flex'} flexDirection={'row'} mb={0.5}>
                    <Iconify icon="gridicons:cross-circle" color="red" marginRight="5px" />
                    <Typography mt={-0.3}>Import CSV</Typography>
                  </Grid>

                  <Grid display={'flex'} flexDirection={'row'} mb={0.5}>
                    <Iconify icon="gridicons:cross-circle" color="red" marginRight="5px" />
                    <Typography mt={-0.3}>Downloadable CSV Reports</Typography>
                  </Grid>
                </Grid>
              </Container>
            </section>
          ) : null}

          <Box
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            alignItems={'center'}
            sx={{
              backgroundImage:
                isFree && !Auth?.isSubscribed()
                  ? 'linear-gradient( rgba(0, 161, 214, 0.54),#fff)'
                  : 'linear-gradient( #fff,#fff)',
              height: '150px',
              marginTop: isFree && !Auth?.isSubscribed() ? '20px' : '-280px',
            }}
          >
            <>
              <Typography sx={{ paddingTop: '10px' }} variant="h3">
                Paid Plans
              </Typography>
              <span
                style={{
                  paddingBottom: '30px',
                }}
              >
                Upgrade to Our Premium Plans for Enhanced Features and Flexibility!
              </span>
            </>
          </Box>
          {/* <Elements stripe={stripePromise}> */}
          {/* <stripe-pricing-table
              pricing-table-id="prctbl_1O4e0zSHlBafozrZHTxMEIgO"
              // pricing-table-id="prctbl_1MyZKcSHlBafozrZoadZbKNz"
              publishable-key="pk_live_51MEUFfSHlBafozrZxctS7UzxWHqaBmHZxXHlnhsfntxjpqkl5Hk2256Smk6Ck2hssTJM5PXYVongmhIuKmzxIGgB00Z6U6jNxz"
              client-reference-id={Auth.getUserLoginId()}
              customer-email={Auth.getEmail()}
              // price={price?.id}
            /> */}

          {/* <stripe-pricing-table
            pricing-table-id="prctbl_1OBw7ISHyeWGBVACJTLrJRLo"
            publishable-key="pk_test_51OBt7HSHyeWGBVACpkg3v6JpyLpwksmcghOd6HJjT7pBlph0cgfQC5fMNZ8k7bThVqG4uGw5z06pFa1u4Qz2QEJv00fmp43iYb"
            client-reference-id={Auth.getUserLoginId()}
            customer-email={Auth.getEmail()}
          /> */}
          <stripe-pricing-table
            pricing-table-id="prctbl_1OBwDaSHyeWGBVACJETbVzTT"
            publishable-key="pk_live_51OBt7HSHyeWGBVACCVgt1TVbzVvlphOOZeq0GIt6tHqeNeBT3sZCUVDAoj1Jcsqyv2iKxajLVdImDvmVUQjWnTVU001TARSAmj"
            client-reference-id={Auth.getUserLoginId()}
            customer-email={Auth.getEmail()}
          />

          {/* <stripe-pricing-table
            pricing-table-id="prctbl_1OCF69SHyeWGBVACmQuOdJrc"
            publishable-key="pk_live_51OBt7HSHyeWGBVACCVgt1TVbzVvlphOOZeq0GIt6tHqeNeBT3sZCUVDAoj1Jcsqyv2iKxajLVdImDvmVUQjWnTVU001TARSAmj"
            client-reference-id={Auth.getUserLoginId()}
            customer-email={Auth.getEmail()}
          /> */}

          {/* </Elements> */}
          <Grid display={'flex'} justifyContent={'center'} mt={8} mb={6}>
            <Typography variant="h3">Frequently Asked Questions</Typography>
          </Grid>
          <Grid
            sx={{
              // marginLeft: '200px',
              // marginRight: '200px',
              margin: '20px',
              borderRadius: '10px',
            }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon="ic:baseline-arrow-forward-ios" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Is it possible for me to cancel at any time?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Certainly! You have the option to cancel either through your dashboard, which will initiate automatic
                  cancellation, or by getting in touch with us directly.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon="ic:baseline-arrow-forward-ios" />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>What do you mean by on-demand checks?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The keyword tracker is designed to update daily to ensure that your data is both accurate and
                  up-to-date. If you require the latest rankings, you have the option to refresh your rank tracker
                  on-demand, as frequently as you wish.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon="ic:baseline-arrow-forward-ios" />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Which search engines are supported by you?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>At present, we solely exhibit search results from Google.</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon="ic:baseline-arrow-forward-ios" />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Can ML - Rank Tracker be effective for Local SEO?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes, ML – Rank Tracker can be useful for Local SEO. By tracking keyword rankings specific to a
                  particular geographic location, it can provide insights into how a website is performing in the local
                  search results. This can help businesses optimize their website and content to better target local
                  audiences and improve their visibility in the local search results.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <div style={{ marginTop: '20px', height: '50px' }}>{/* Content after Accordions */}</div>
          </Grid>
        </>
      )}
    </>
  );
}

export default PricingPage;
